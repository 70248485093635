<template>
  <div>
    <link
      rel="stylesheet"
      href="https://unpkg.com/leaflet@1.7.1/dist/leaflet.css"
      integrity="sha512-xodZBNTC5n17Xt2atTPuE1HxjVMSvLVW9ocqUKLsCC5CXdbqCmblAshOMAS6/keqq/sMZMZ19scR4PsZChSR7A=="
      crossorigin=""
    />
    <l-map
      ref="map"
      :min-zoom="minZoom"
      :max-zoom="maxZoom"
      :crs="crs"
      :style="mapStyle"
      @click="onMapClicked"
    >
      <div class="infobox" v-if="showInfobox">
        <h1 class="treeHeader">{{ chosenPin.name }}</h1>
        <div class="treeImage">
          <img :src="chosenPin.img" />
        </div>
        <div class="treeDescription">
          <p>Nussbaum, Klasse I</p>
          <a :href="link" target="_blank">Hier NFT kaufen</a>
        </div>
      </div>
      <l-image-overlay :url="url" :bounds="bounds" />

      <l-marker
        v-for="pin in pins"
        :key="pin.name"
        :lat-lng="pin"
        @click="pinClicked(pin)"
      >
        <l-icon
          :icon-size="dynamicSize"
          :icon-anchor="dynamicAnchor"
          :icon-url="icon"
        >
        </l-icon>
        <l-popup
          ><div>
            <h3>{{ pin.name }}</h3>
            <!-- <a :href="`${pin.link}`" target="_blank">{{ pin.text }}</a> -->
          </div></l-popup
        >
      </l-marker>
      <!-- <l-polyline :lat-lngs="travel" /> -->
    </l-map>
  </div>
</template>

<script >
import { Icon, CRS } from "leaflet";
import {trees} from "./Data.js";

import {
  LMap,
  LImageOverlay,
  LMarker,
  LIcon,
  LPopup /* LPolyline */,
} from "vue2-leaflet";

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

export default {
  components: {
    LMap,
    LImageOverlay,
    LMarker,
    LPopup,
    LIcon,
    /*  LPolyline, */
  },
  props: {
    mapSize: { required: true },
    windowSize: { required: true },
    color1: { required: true },
  },
  methods: {
    pinClicked(e) {
      console.log("pinClicked: ", e);
      this.chosenPin = e;
      this.showInfobox = true;
      /* this.$refs.map.mapObject.setView([0, 0], -1); */
    },
    onMapClicked(e) {
      console.log("onMapClicked: ", e, e.latlng);
      console.log("collectedMarkers: ", this.collectedMarkers);
      console.log("windowSize: ", this.windowSize);
      console.log("chosenPin: ", this.chosenPin);
      console.log("trees: ", trees)
      this.$emit("add-marker", e.latlng);
      this.showInfobox = false;
    },
  },

  /* setView(<LatLng> center, <Number> zoom, <Zoom/pan options> options?) */
  /* transpose / move the map */
  mounted() {
    this.$refs.map.mapObject.setView(
      [this.mapSize.height, this.mapSize.width],
      -1
    );
  },
  computed: {
    dynamicSize() {
      return [this.iconSize * 0.8, this.iconSize];
    },
    dynamicAnchor() {
      return [(this.iconSize * 0.8) / 2];
    },
    mapStyle() {
      return {
        // size of map container (with -+ zoom buttons)
        height: this.windowSize.height + "px",
        width: this.windowSize.width + "px",
      };
    },
  },
  data() {
    return {
      text: "Hier kaufen!",
      link: "https://planetarian.earth/",
      icon: require("../assets/map-pin.png"),
      img: require("../assets/cherry_tree.png"),
      // lng = x (left to right), lat = y (bottom to top)
      chosenPin: {
        name: "default",
        text: "",
        lat: 1052,
        lng: 2366.425003051758,
      },
      showInfobox: false,
      iconSize: 34,
      url: "map-drone-2.JPG",
      // bounds: how big is the actual map (not the container with zoom buttons)
      bounds: [
        [0, 0],
        [this.mapSize.height * 2, this.mapSize.width * 2],
      ],
      minZoom: -1,
      maxZoom: 18,
      crs: CRS.Simple,
      pins: trees,
      /*  [
        // lng = x (left to right), lat = y (bottom to top)
        {
          name: "NFTree #1",
                    text: "Hier kaufen!",
          link: "https://planetarian.earth/",
          icon: require("../assets/map-pin.png"),
          img: require("../assets/cherry_tree.png"),
          lat: 1052,
          lng: 2366.425003051758,
        },
      ],
       */
      /*       travel: [
        [145.0, 175.2],
        [8.3, 218.7],
      ], */
    };
  },
};
</script>

<style >
.leaflet-popup {
  /* background: green !important; */
  bottom: 25px !important;
}
.vue2leaflet-map {
  background-color: rgb(216, 208, 156) !important;
}
.vue2leaflet-map:hover {
  cursor: default;
}
.infobox {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  position: fixed;
  top: 100px;
  right: 0;
  width: 400px;
  border-radius: 20px;
  margin-right: 20px;
  background-color: white;
  color: black;
  z-index: 999;
}
.treeHeader {
  margin-left: auto;
  margin-right: auto;
}
.treeImage {
  margin-left: auto;
  margin-right: auto;
}
.treeImage > img {
  margin-top: 20px;
  width: 300px;
  border-radius: 20px;
}
.treeDescription {
  margin-left: 50px;
  font-size: 20px;
  margin-bottom: 20px;
}
.treeDescription > a {
  color: black !important;
}
</style>