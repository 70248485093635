<template>
  <div id="app" style="font-family: 'Montserrat'">
    <div class="main-container">
      <div class="heading">
        <img :src="logo" />
      </div>
      <div class="map-container">
        <interactive-map
          :mapSize="mapSize"
          :windowSize="{ width: windowWidth, height: windowHeight }"
          @add-marker="addMarker"
          :color1="color1"
        />
      </div>
    </div>
  </div>
</template>

<script>
import InteractiveMap from "./components/InteractiveMap.vue";

export default {
  name: "App",
  components: {
    InteractiveMap,
  },
  methods: {
    addMarker(latlng) {
      console.log("markerAdded: ", latlng);
      this.addedMarkers.push(latlng);
      console.log("addedMarkers: ", this.addedMarkers);
      var s = "";
      for (var i in this.addedMarkers) {
        s +=
          "{'name': 'NFTree #" +
          i +
          " ' " +
          ", 'lat': " +
          this.addedMarkers[i].lat +
          ", 'lng': " +
          this.addedMarkers[i].lng +
          "},";
      }
      console.log("s: ", s);
    },
    onResize() {
      this.windowHeight = window.innerHeight;
      this.windowWidth = window.innerWidth;
      console.log(this.windowWidth, this.windowHeight);
    },
  },
  data() {
    return {
      logo: require("./assets/NFTree_Logo.png"),
      mapSize: { width: 2302, height: 1260 },
      addedMarkers: [],
      color1: "rgb(216,208,156)",
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth,
      
    };
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
};
</script>

<style>
.heading {
  position: fixed;
  top: 30px;
  left: calc(50% - 150px);
  color: black;
  z-index: 999;
}
.heading > img {
  width: 300px;
}
.map-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
h1 {
  margin-left: 100px;
}
.logo {
  display: inline;
  float: center;
}
.logo > img {
  width: 100px;
  height: 100px;
}
</style>
