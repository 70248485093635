const trees_coordinates = [
    {'name': 'NFTree #0 ' , 'lat': 1352, 'lng': 1746},{'name': 'NFTree #1 ' , 'lat': 1350, 'lng': 1800},{'name': 'NFTree #2 ' , 'lat': 1356, 'lng': 1858},{'name': 'NFTree #3 ' , 'lat': 1348, 'lng': 1902},{'name': 'NFTree #4 ' , 'lat': 1348, 'lng': 1966},{'name': 'NFTree #5 ' , 'lat': 1350, 'lng': 2014},{'name': 'NFTree #6 ' , 'lat': 1352, 'lng': 2078},{'name': 'NFTree #7 ' , 'lat': 1350, 'lng': 2118},{'name': 'NFTree #8 ' , 'lat': 1350, 'lng': 2176},{'name': 'NFTree #9 ' , 'lat': 1352, 'lng': 2228},{'name': 'NFTree #10 ' , 'lat': 1324, 'lng': 2228},{'name': 'NFTree #11 ' , 'lat': 1324, 'lng': 2172},{'name': 'NFTree #12 ' , 'lat': 1324, 'lng': 2110},{'name': 'NFTree #13 ' , 'lat': 1324, 'lng': 2066},{'name': 'NFTree #14 ' , 'lat': 1324, 'lng': 2014},{'name': 'NFTree #15 ' , 'lat': 1320, 'lng': 1956},{'name': 'NFTree #16 ' , 'lat': 1324, 'lng': 1900},{'name': 'NFTree #17 ' , 'lat': 1320, 'lng': 1850},{'name': 'NFTree #18 ' , 'lat': 1322, 'lng': 1798},{'name': 'NFTree #19 ' , 'lat': 1320, 'lng': 1744},
]

export var trees_mod = []

for (var i in trees_coordinates) {
    var obj = trees_coordinates[i]
    var id = trees_coordinates[i].name.split('#')[1].trim()
    obj.id = id
    obj.img = require("../assets/trees/tree_" + id + ".jpg"),
        trees_mod.push(obj)
}

export var trees = trees_mod

